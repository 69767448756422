import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';

export function pathInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const { host, prefix, production, subDomain } = environment;
  let url: string;
  if (production) {
    if (/(pages)\.(dev)$/.test(location.hostname)) {
      url = host + prefix + req.url;
    } else {
      url = `${location.protocol}//${subDomain}.${location.hostname}${req.url}`;
    }
  } else {
    url = host + prefix + req.url;
  }
  return next(req.clone({ url }));
}
