import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { effects, reducers } from './store';
import {
  errorInterceptor,
  headersInterceptor,
  pathInterceptor,
} from '@common/interceptors';
import localeRu from '@angular/common/locales/ru-KZ';
import { registerLocaleData } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import * as Sentry from '@sentry/angular-ivy';
registerLocaleData(localeRu, 'ru-KZ');

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
    ),
    provideHttpClient(
      withInterceptors([headersInterceptor, pathInterceptor, errorInterceptor]),
    ),
    provideStore(reducers),
    provideEffects(effects),
    importProvidersFrom(StoreDevtoolsModule.instrument()),
    {
      provide: LOCALE_ID,
      useValue: 'ru-KZ',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
